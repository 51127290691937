@media screen and (min-width: $breakpoint) {
    .layout-container {
        &.layout-horizontal {
            .layout-sidebar {
                width: 100%;
                height: 3rem;
                top: 4rem;
                position: fixed;
                z-index: 998;
                overflow: visible;
                flex-direction: row;
                padding: 0 2rem;

                .layout-menu-container {
                    overflow: auto;
                    padding-bottom: 0;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                }
            }

            .layout-topbar {
                .layout-topbar-start {
                    .layout-menu-button {
                        display: none;
                    }
                }
            }

            .layout-content-wrapper {
                padding-top: 7rem;
            }

            .layout-menu {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: center;
                height: 100%;

                ul {
                    display: none;
                }

                .layout-root-menuitem {
                    border-radius: var(--border-radius);

                    >.layout-menuitem-root-text {
                        display: none;
                    }

                    >a {
                        display: flex;
                        align-items: center;
                        padding: .75rem;
                        border-radius: 0;
                        cursor: pointer;
                        outline: none;
                        color: var(--menuitem-text-color);
                        transition: background-color var(--transition-duration);

                        .layout-submenu-toggler {
                            display: block;
                            margin-left: auto;
                            transition: transform 0.2s;
                        }

                        .layout-menuitem-icon {
                            font-size: 1.25rem;
                            color: var(--menuitem-text-color);
                        }

                        .layout-menuitem-text {
                            font-size: 0.875rem;
                            display: block;
                            margin-left: .75rem;
                            margin-right: .75rem;
                            white-space: nowrap;
                        }

                        &:hover {
                            background-color: var(--menuitem-hover-bg);
                        }
                    }

                    >ul {
                        position: absolute;
                        left: auto;
                        top: 3rem;
                        min-width: 15rem;
                        background-color: var(--menu-bg);
                        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
                        padding: .5rem 0;
                        border-radius: var(--border-radius);
                        max-height: 20rem;
                        overflow: auto;
                        z-index: 999;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        a {
                            padding-right: .5rem;
                        }

                        li {
                            a {
                                padding-left: 0.5rem;
                            }

                            li {
                                a {
                                    padding-left: 1rem;
                                }

                                li {
                                    a {
                                        padding-left: 1.5rem;
                                    }

                                    li {
                                        a {
                                            padding-left: 2rem;
                                        }

                                        li {
                                            a {
                                                padding-left: 2.5rem;
                                            }

                                            li {
                                                a {
                                                    padding-left: 3rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.layout-menu-profile-start {
                .layout-menu-profile {
                    border-bottom: 0 none;

                    >ul {
                        left: 0;
                    }
                }
            }

            &.layout-menu-profile-end {
                .layout-menu-profile {
                    border-top: 0 none;

                    >ul {
                        right: 0;
                    }
                }
            }

            .layout-menu-profile {
                position: relative;
                overflow: visible;
                width: auto;
                height: 100%;

                >button {
                    padding: 0 .75rem;
                    width: auto;
                    height: 100%;

                    .layout-menu-profile-toggler {
                        margin-left: .5rem;
                    }
                }

                >ul {
                    position: absolute;
                    top: 3rem;
                    min-width: 15rem;
                    background-color: var(--menu-bg);
                    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
                    padding: 1rem;
                    border-radius: var(--border-radius);
                    max-height: 20rem;
                    overflow: auto;
                    z-index: 999;
                    transform-origin: center top;

                    >li {
                        >button {
                            padding: 0.75rem .5rem;
                        }
                    }
                }
            }
        }
    }
}
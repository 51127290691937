.table-layout-fixed {
  table-layout: fixed;
}

.table-padding-50 {
  padding: 0.50rem 0.50rem;
}

.word-wrap-break-word {
  word-wrap: break-word;
}
.white-space-nowrap {
  white-space: nowrap;
}

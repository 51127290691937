@media screen and (min-width: $breakpoint) {
    .layout-container {
        &.layout-static {
            .layout-sidebar {
                transition: transform .3s cubic-bezier(0, 0, 0.2, 1);
            }

            .layout-content-wrapper {
                margin-left: 17rem;
                transition: margin-left .3s cubic-bezier(0, 0, 0.2, 1);
            }

            .layout-topbar {
                .layout-menu-button {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &.layout-static-inactive {
            .layout-sidebar {
                transform: translateX(-100%);
            }

            .layout-content-wrapper {
                margin-left: 0;
            }

            .layout-topbar {
                .layout-menu-button {
                    i {
                        transform: rotate(0deg);
                    }
                }
            }
        }

        &.layout-overlay {
            .layout-content-wrapper {
                margin-left: 0;
            }

            .layout-sidebar {
                z-index: 999;
                transform: translateX(-100%);
                transition: transform .3s cubic-bezier(0, 0, 0.2, 1);
            }

            &.layout-overlay-active {
                .layout-sidebar {
                    transform: translateX(0);
                }

                .layout-topbar {
                    .layout-menu-button {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: ($breakpoint - 1)) {
    .blocked-scroll {
        overflow: hidden;
    }

    .layout-container {
        .layout-content-wrapper {
            margin-left: 0;
        }

        .layout-sidebar {
            z-index: 999;
            transform: translateX(-100%);
            transition: transform .3s cubic-bezier(0, 0, 0.2, 1);
        }

        &.layout-mobile-active {
            .layout-sidebar {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
                animation: px-mask-in .3s cubic-bezier(0, 0, 0.2, 1);
            }

            .layout-topbar {
                .layout-menu-button {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .layout-mask {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 998;
            width: 100%;
            height: 100%;
            background-color: var(--maskbg);
        }

        &.layout-topbar-menu-active {
            .layout-topbar {
                .layout-topbar-end {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: stretch;
                    position: fixed;
                    top: 4rem;
                    width: 100%;
                    background-color: var(--topbar-bg);
                    padding-left: 0;

                    .layout-megamenu {
                        height: 3rem;

                        &.p-megamenu .p-megamenu-root-list>.p-menuitem {
                            position: static;

                            .p-megamenu-panel {
                                position: absolute;
                                left: 1rem;
                                right: 1rem;
                                max-height: 20rem;
                                overflow: auto;
                            }

                            .p-megamenu-grid {
                                flex-direction: column;
                            }
                        }
                    }

                    .layout-topbar-items {
                        justify-content: space-between;
                        align-items: center;
                        height: 3rem;
                        padding: 0 1rem;

                        >li {
                            position: static;

                            >div {
                                left: 1rem;
                                right: 1rem;
                            }
                        }
                    }
                }

                .layout-topbar-actions-start {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 3rem;
                }
            }
        }
    }

    .layout-topbar {
        display: flex;
        flex-direction: column;

        .layout-topbar-start {
            padding: 0 1rem;
            width: 100%;
            height: 4rem;
            justify-content: start;

            .layout-menu-button {
                position: relative;
                margin-top: 0;
                margin-left: 1rem;
                top: auto;
            }

            .layout-topbar-mobile-button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
                transition: background-color var(--transition-duration);

                &:hover {
                    background-color: var(--topbar-bg);
                }
            }
        }

        .layout-topbar-end {
            display: none;
        }
    }
}

@media screen and (min-width: 1729px) {
    .layout-content {
        width: 1504px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}
.layout-menu-bluegrey {
    --menu-bg: #37474F;
    --root-menuitem-text-color: #ffffff;
    --menuitem-text-color: rgba(255,255,255,.6);
    --menuitem-hover-bg: rgba(255,255,255,.12);
    --active-menuitem-text-color: #ffffff;
    --active-menuitem-bg: rgba(255,255,255,.24);
    --inline-menu-border-color: rgba(255,255,255,.24);
}


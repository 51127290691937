/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";
@import "assets/layout/styles/custom-position/custom-position.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Table */
@import "assets/layout/styles/layout/table-custom/table-custom";

/*Button*/
@import "assets/layout/styles/custom-button/custom-button.scss";

.custome-container {
  position: relative;

.p-splitButton {
    position: absolute;
    right: 0;
  }
}

.p-dialog .p-dialog-content {
  background-color: var(--surface-ground);
}
.filter-button-margin {
  margin-top: 1.85rem !important;
}

.p-dropdown{
    width:inherit;
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid var(--surface-300);
}

.w-40
{
    width: 40%;
}

.w-5rem{
 width: 5rem;
}

.w-10rem{
  width: 10rem;
 }

.overflow-wrap-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
